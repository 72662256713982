<template>
  <div>
    <w-top-bar>Editar Datos para envio</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        {{ item }}
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <div>
            <div class="bg-white rounded-md shadow p-5 grid grid-cols-2 gap-4">
              <w-input label="Correo electrónico" v-model="clone.email" />
              <w-input label="Nombre" v-model="clone.name" />
              <w-input label="Apellido" v-model="clone.lastName" />

              <w-input label="Dirección" v-model="clone.address" />
              <w-input label="Número" v-model="clone.addressNumber" />
              <w-input label="Código Postal" v-model="clone.zipCode" />
              <w-input label="Ciudad" v-model="clone.city" />
              <w-input label="Estado" v-model="clone.state" />
              <w-input label="País" v-model="clone.country" />
              <w-input label="Detalle" v-model="clone.details" />
            </div>

            <div class="flex justify-end py-2">
              <div
                v-if="saved"
                class="flex items-center text-sm font-medium text-green-600"
              >
                <div>Cambios guardados</div>
                <svg
                  class="
                    w-5
                    h-5
                    ml-1
                    mr-2
                    font-normal
                    text-green-600
                    transition
                    duration-150
                    ease-in-out
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                v-if="saving"
                class="flex items-center text-sm font-medium text-gray-600"
              >
                <div>Guardando cambios</div>

                <svg
                  class="
                    w-5
                    h-5
                    ml-1
                    mr-2
                    font-normal
                    text-gray-600
                    transition
                    duration-150
                    ease-in-out
                    animate-spin
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="
                  flex
                  items-center
                  px-4
                  py-2
                  text-sm
                  font-bold
                  text-white
                  transition
                  duration-150
                  ease-in-out
                  bg-green-500
                  rounded
                  hover:bg-green-700
                "
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>

<script>
import { FeathersVuexFormWrapper } from "feathers-vuex";
// Main JS (in UMD format)

export default {
  name: "EditShippings",
  components: {
    FeathersVuexFormWrapper,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { Shippings } = this.$FeathersVuex.api;

      return this.id === "new"
        ? new Shippings()
        : Shippings.getFromStore(this.id);
    },
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (val === "new") {
          return;
        }
        const { Shippings } = this.$FeathersVuex.api;
        const existingRecord = Shippings.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Shippings.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      hasExtra: false,
      saved: false,
      saving: false,
    };
  },
  methods: {
    async doSave(save) {
      this.saved = false;
      this.saving = true;
      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveReponse(saved);
    },
    handleSaveReponse(savedShippings) {
      // Redirect to the newly-saved item
      console.log("handleSaveReponse", savedShippings);
      if (this.id === "new") {
        this.$router.push({ params: { id: savedShippings._id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
